import React, { useState } from 'react';
import DiffMatchPatch from 'diff-match-patch';
import styles from './TextCompare.module.css';

const TextCompare = () => {
  const [text1, setText1] = useState('');
  const [text2, setText2] = useState('');

  const handleText1Change = (e) => setText1(e.target.value);
  const handleText2Change = (e) => setText2(e.target.value);

  const getDiffHtml = (a, b) => {
    const dmp = new DiffMatchPatch();
    const diffs = dmp.diff_main(a, b);
    dmp.diff_cleanupSemantic(diffs);

    return diffs.map(([type, text], index) => {
      if (type === DiffMatchPatch.DIFF_INSERT) {
        return <span key={index} className={styles.added}>{text}</span>;
      } else if (type === DiffMatchPatch.DIFF_DELETE) {
        return <span key={index} className={styles.removed}>{text}</span>;
      } else {
        return <span key={index}>{text}</span>;
      }
    });
  };

  const differences = getDiffHtml(text1, text2);

  return (
    <div className={styles['tool-content']}>
      <div className={styles['input-section']}>
        <textarea
          className={styles['text-area']}
          value={text1}
          onChange={handleText1Change}
          placeholder="Enter first text here..."
        />
        <textarea
          className={styles['text-area']}
          value={text2}
          onChange={handleText2Change}
          placeholder="Enter second text here..."
        />
      </div>
      <div className={styles['result-section']}>
        <h3>Differences:</h3>
        <pre className={styles['result-text']}>{differences}</pre>
      </div>
    </div>
  );
};

export default TextCompare;
