import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment-timezone';
import './UnixTimeConverter.module.css'; // Import CSS module
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { getUserInput } from '../../../utils/UserState';
import styles from './UnixTimeConverter.module.css';

const UnixTimeConverter = ({ userId }) => {
  const toolName = 'Unix Time Converter';
  const initialInputValue = getUserInput(userId, toolName) || '';
  
  const [inputType, setInputType] = useState('iso');
  const [inputValue, setInputValue] = useState(initialInputValue);
  const [date, setDate] = useState(null);
  const [selectedTimezones, setSelectedTimezones] = useState([]);
  const [timezoneSearch, setTimezoneSearch] = useState('');

  const handleNow = useCallback(() => {
    const now = moment();
    setInputValue(inputType === 'unix' ? String(now.unix()) : now.format('YYYY-MM-DDTHH:mm'));
    setDate(now);
  }, [inputType, setInputValue, setDate]);

  useEffect(() => {
    handleNow();
  }, [handleNow]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);

    let parsedDate = null;
    if (inputType === 'unix') {
      const unixTimestamp = parseInt(value, 10);
      if (!isNaN(unixTimestamp)) {
        parsedDate = moment.unix(unixTimestamp);
      }
    } else if (inputType === 'iso') {
      parsedDate = moment(value);
    }

    if (parsedDate && parsedDate.isValid()) {
      setDate(parsedDate);
    } else {
      setDate(null);
    }
  };

  const handleInputTypeChange = (event) => {
    const value = event.target.value;
    setInputType(value);
    if (value === 'unix') {
      setInputValue(date ? String(date.unix()) : '');
    } else if (value === 'iso') {
      setInputValue(date ? date.format('YYYY-MM-DDTHH:mm') : '');
    }
    setDate(null);
  };

  const handleClear = () => {
    setInputValue('');
    setDate(null);
  };

  const handleTimezoneChange = (event) => {
    if (event.key === 'Enter' || event.type === 'change' || event.type === 'selected') {
      const selectedTz = event.target.value;
      if (selectedTz && moment.tz.names().includes(selectedTz) && !selectedTimezones.includes(selectedTz)) {
        setSelectedTimezones([...selectedTimezones, selectedTz]);
        setTimezoneSearch('');
      }
    }
  };

  const removeTimezone = (timezone) => {
    setSelectedTimezones(selectedTimezones.filter(tz => tz !== timezone));
  };

  const formatDateDetails = (date) => {
    if (!date) return null;
    return {
      'Local Time': date.format('YYYY-MM-DD HH:mm:ss'),
      'UTC': date.utc().format('YYYY-MM-DD HH:mm:ss'),
      'Relative Time': date.fromNow(),
      'Unix Time': date.unix(),
      'Day of Year': date.dayOfYear(),
      'Week Of Year': date.isoWeek(),
      'Is Leap Year': date.isLeapYear() ? 'Yes' : 'No'
    };
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const dateDetails = formatDateDetails(date);

  return (
    <div className={styles['tool-content']}>
      <div className={styles['input-section']}>
        <div className={styles['input-type-selector']}>
          <label>
            Input Type:
            <select value={inputType} onChange={handleInputTypeChange}>
              <option value="unix">Unix Time</option>
              <option value="iso">ISO 8601</option>
            </select>
          </label>
        </div>
        <div className={styles['input-actions']}>
          <input
            type={inputType === 'unix' ? 'number' : 'datetime-local'}
            value={inputValue}
            onChange={handleInputChange}
          />
          <button onClick={handleNow}>Now</button>
          <button onClick={handleClear}>Clear</button>
        </div>
        {dateDetails && (
          <div className={styles['date-details']}>
            {Object.keys(dateDetails).map(key => (
              <p key={key} className={styles['date-details-item']}>
                <span className={styles['label']}>{key}:</span>
                <span className={styles['highlight']}>{dateDetails[key]}</span>
                <button onClick={() => copyToClipboard(dateDetails[key])}>
                  <FontAwesomeIcon icon={faCopy} title="Copy to Clipboard" />
                </button>
              </p>
            ))}
          </div>
        )}
      </div>
      <div className={styles['timezone-section']}>
        <label>
          Select Timezones:
          <input
            type="text"
            list="timezone-list"
            placeholder='Add Timezone...'
            value={timezoneSearch}
            onChange={(e) => setTimezoneSearch(e.target.value)}
            onKeyDown={handleTimezoneChange}
            onSelect={handleTimezoneChange}
          />
          <datalist id="timezone-list">
            {moment.tz.names().map(tz => (
              <option key={tz} value={tz}>{tz}</option>
            ))}
          </datalist>
        </label>
        {selectedTimezones.length > 0 && (
          <div className={styles['selected-timezones']}>
            {selectedTimezones.map(tz => (
              <div key={tz} className={styles['timezone-row']}>
                <span>{tz}: {date ? date.tz(tz).format('YYYY-MM-DD HH:mm:ss') : 'Invalid Date'}</span>
                <button onClick={() => removeTimezone(tz)}>Remove</button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default UnixTimeConverter;
