import React from 'react';
import styles from './Footer.module.css';
import config from '../../config/default';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div>© {config.AppName} {currentYear}. All rights reserved.</div>
        <div className={styles.contact}>
          <a href="https://github.com/the-code-wrench/public-docs/issues">Support</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
