import React, { useState, useRef, useEffect } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/addon/fold/foldgutter.css';
import 'codemirror/addon/fold/foldcode';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/brace-fold';
import 'codemirror/addon/lint/lint.css';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/json-lint';
import 'codemirror/addon/edit/closebrackets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompress, faSortAlphaDown, faStream, faEraser, faCopy, faUndo, faRedo } from '@fortawesome/free-solid-svg-icons';
import styles from './JsonFormatterValidator.module.css'; // Import CSS module
import { getUserInput, setUserInput } from '../../../utils/UserState';
import jsonlint from 'jsonlint-mod'; // Import jsonlint

// Attach jsonlint to window
window.jsonlint = jsonlint;

const JsonFormatterValidator = ({ userId }) => {
  const toolName = 'JSON Formatter and Validator';
  const initialJsonInput = getUserInput(userId, toolName) || '';

  const [jsonInput, setJsonInput] = useState(initialJsonInput);
  const [error, setError] = useState('');
  const historyRef = useRef({ past: [], future: [] });
  const [currentOperation, setCurrentOperation] = useState('');
  const editorRef = useRef(null);

  const handleJsonOperation = (operation) => {
    try {
      if (operation === 'clear') {
        addHistory(jsonInput);
        setJsonInput('');
        setError('');
        setCurrentOperation(operation);
        return;
      }

      if (jsonInput.trim() === '') {
        setError('');
        return;
      }

      const parsedJson = JSON.parse(jsonInput);
      let resultJson;
      switch (operation) {
        case 'format':
          resultJson = JSON.stringify(parsedJson, null, 2);
          break;
        case 'compact':
          resultJson = JSON.stringify(parsedJson);
          break;
        case 'sort':
          resultJson = JSON.stringify(
            Object.keys(parsedJson).sort().reduce((obj, key) => {
              obj[key] = parsedJson[key];
              return obj;
            }, {}),
            null,
            2
          );
          break;
        case 'copy':
          navigator.clipboard.writeText(jsonInput);
          return;
        default:
          return;
      }
      addHistory(jsonInput);
      setJsonInput(resultJson);
      setCurrentOperation(operation);
      setError('');
    } catch (err) {
      setError(`Error: ${err.message}`);
    }
  };

  const addHistory = (input) => {
    historyRef.current.past.push(input);
    historyRef.current.future = [];
  };

  const handleUndo = () => {
    const history = historyRef.current;
    if (history.past.length === 0) return;
    history.future.push(jsonInput);
    const previousInput = history.past.pop();
    setJsonInput(previousInput);
  };

  const handleRedo = () => {
    const history = historyRef.current;
    if (history.future.length === 0) return;
    history.past.push(jsonInput);
    const nextInput = history.future.pop();
    setJsonInput(nextInput);
  };

  const isCompactOperation = currentOperation === 'compact';

  const toolbarConfig = [
    { icon: faStream, title: "Format JSON", operation: "format", onClick: handleJsonOperation },
    { icon: faCompress, title: "Compact JSON", operation: "compact", onClick: handleJsonOperation },
    { icon: faSortAlphaDown, title: "Sort JSON", operation: "sort", onClick: handleJsonOperation },
    { icon: faEraser, title: "Clear JSON", operation: "clear", onClick: handleJsonOperation },
    { icon: faCopy, title: "Copy to Clipboard", operation: "copy", onClick: handleJsonOperation },
    { icon: faUndo, title: "Undo", onClick: handleUndo, disabled: historyRef.current.past.length === 0 },
    { icon: faRedo, title: "Redo", onClick: handleRedo, disabled: historyRef.current.future.length === 0 }
  ];

  useEffect(() => {
    const editor = editorRef.current;
    if (editor) {
      const lineCount = editor.editor.lineCount();
      const newHeight = lineCount * 24; // 24px is an estimate of a line height
      editor.editor.setSize(null, newHeight);
    }
  }, [jsonInput]);

  return (
    <div className={styles['tool-content']}>
      <div className={styles['toolbar']}>
        {toolbarConfig.map((button, index) => (
          <button key={index} onClick={() => button.onClick(button.operation)} disabled={button.disabled}>
            <FontAwesomeIcon icon={button.icon} title={button.title} />
          </button>
        ))}
      </div>
      <div className={styles['editor-container']}>
        <CodeMirror
          className={`${styles['editor-card']} ${styles['custom-codemirror']}`} // Ensure to add custom-codemirror class for specific styling
          value={jsonInput}
          options={{
            mode: 'application/json',
            theme: 'material',
            lineNumbers: true,
            foldGutter: true,
            gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
            lineWrapping: isCompactOperation,
            lint: true,
            autoCloseBrackets: true,
            minHeight: '400px',
          }}
          onBeforeChange={(editor, data, value) => {
            setJsonInput(value);
            setError('');
          }}
          onChange={(editor, data, value) => {
            try {
              if (value.trim() === '') {
                setError('');
                return;
              }
              JSON.parse(value);
              setError('');
            } catch (err) {
              setError(`${err.message}`);
            }
            // Update user input in local storage on change
            setUserInput(userId, toolName, value);
          }}
          editorDidMount={(editor) => {
            editorRef.current = { editor };
          }}
        />
      </div>
      {error && <div className={styles['tool-error']}>{error}</div>}
    </div>
  );
};

export default JsonFormatterValidator;
