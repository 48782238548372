import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './App';
import './styles/global.css';
import { createRoot } from 'react-dom/client';
import Home from './components/Home/Home';

createRoot(document.getElementById('root')).render(
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/tools/:slug" element={<App />} />
    </Routes>
  </Router>
);
