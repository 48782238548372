import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/image/logo.png';
import styles from './Header.module.css';
import config from '../../config/default';

const Header = () => {
  return (
    <div className={styles.header}>
      <div className={styles.companyName}>
        <img src={logo} alt="Logo" className={styles.logo}/>
        <div className={styles.companyText}>{config.AppName}</div>
      </div>
      <div className={styles.menuContainer}>
        <Link to="/" className={styles.menu}>Home</Link>
        {/* <div className={styles.menu}>Menu</div>
        <div className={styles.menu}>Contact</div> */}
      </div>
    </div>
  );
};

export default Header;
