import React, { useEffect } from 'react';
import styles from './ToolPlayground.module.css';
import Tools from '../../config/ToolsConfig';
import config from '../../config/default';

const ToolPlayground = ({ selectedTool, userId }) => {
  const selectedToolData = Tools.find(tool => tool.slug === selectedTool) || {};
  const toolName = selectedToolData.name || config.ToolNotFound;
  const SelectedComponent = selectedToolData.component;

  useEffect(() => {
    document.title = `${toolName} - ${config.AppName}`;
    window.history.replaceState({}, '', `/tools/${selectedTool}`);
  }, [toolName, selectedTool]);

  return (
    <div className={styles['tool-playground']}>
      <div className={styles['tool-playground-header']}>{toolName}</div>
      <div className={styles['tool-content']}>
        {SelectedComponent ? (
          <SelectedComponent userId={userId} />
        ) : (
          <div className={styles['tool-not-found']}>{toolName}</div>
        )}
      </div>
    </div>
  );
};

export default ToolPlayground;
