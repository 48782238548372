import config from '../config/default';

const getUserState = (userId) => {
  try {
    const storedState = localStorage.getItem(`userState_${userId}`);
    return storedState ? JSON.parse(storedState) : {};
  } catch (error) {
    console.error(`Error retrieving user state for userId ${userId}:`, error);
    return {};
  }
};

const setUserState = (userId, state) => {
  try {
    localStorage.setItem(`userState_${userId}`, JSON.stringify(state));
  } catch (error) {
    console.error(`Error setting user state for userId ${userId}:`, error);
  }
};

const getUserSelectedTool = (userId) => {
  const userState = getUserState(userId);
  return userState.selectedTool || config.defaultToolName;
};

const setUserSelectedTool = (userId, selectedTool) => {
  const userState = getUserState(userId);
  setUserState(userId, { ...userState, selectedTool });
};

const getUserInput = (userId, selectedTool) => {
  const userState = getUserState(userId);
  return userState[selectedTool]?.userInput || '';
};

const setUserInput = (userId, selectedTool, userInput) => {
  let userState = getUserState(userId);
  if (!userState[selectedTool]) {
    userState[selectedTool] = {};
  }
  
  userState[selectedTool].userInput = userInput;
  
  setUserState(userId, userState);
};

export { getUserSelectedTool, setUserSelectedTool, getUserInput, setUserInput };
