import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Tools from '../../config/ToolsConfig';
import ReactGA from 'react-ga';
import styles from './ToolCard.module.css';

const ToolCard = ({ setSelectedTool, onExpand, onMinimize }) => {
  const [input, setInput] = useState('');
  const [result, setResult] = useState(Tools);
  const [expanded, setExpanded] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);

  const searchTools = (input) => {
    setInput(input);
    const filteredTools = Tools.filter(tool => tool.name.toLowerCase().includes(input.toLowerCase()));
    setResult(filteredTools);
    ReactGA.event({
      category: 'User',
      action: 'Tool Search',
      label: input
    });
    if (input) {
      setExpanded(true);
      onExpand();
    } else {
      setExpanded(false);
      onMinimize();
    }
  };

  useEffect(() => {
    if (expanded) {
      onExpand();
    }
  }, [expanded, onExpand]);

  return (
    <div
      className={`${styles['tool-card']} ${expanded ? styles['expanded'] : ''}`}
      onMouseEnter={() => {
        if (!input) setExpanded(true);
      }}
      onMouseLeave={() => {
        if (!input && !inputFocused) setExpanded(false);
      }}
    >
      <div className={`${styles['toolbar']} ${styles['tool-item']}`}>
        <FontAwesomeIcon icon={faSearch} className={styles['search-icon']} />
        <input
          type="text"
          className={`${styles['search-bar']} ${expanded ? styles['input-visible'] : styles['input-hidden']}`}
          placeholder="Search tools..."
          value={input}
          onChange={(e) => searchTools(e.target.value)}
          onFocus={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}
        />
      </div>
      <div className={styles['tool-list']}>
        {
          result.map(tool => (
            <a key={tool.slug} href={`/tool/${tool.slug}`} className={styles['tool-item']} onClick={(e) => { e.preventDefault(); setSelectedTool(tool.slug); }}>
              <FontAwesomeIcon icon={tool.icon} className={styles['tool-icon']} />
              <span className={styles['tool-name']}>
                {expanded ? tool.name : null}
              </span>
            </a>
          ))
        }
      </div>
    </div>
  );
};

export default ToolCard;
