import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const Home = () => {
  return (
    <div>
      <Header />
      <section id="hero">
        <div className="hero-content">
          <h1>The Code Wrench: Your All-in-One Developer Toolkit</h1>
          <p>Simplify your coding tasks effortlessly with our comprehensive suite of tools.</p>
          <Link to="/tools/unix-time-converter" className="cta-button">Get Started</Link>
        </div>
      </section>

      <section id="developer-tools">
        <h2>Developer Tools</h2>
        <div className="tools-list">
          <div className="tool-card">
            <h3>Unix Time Converter</h3>
            <p>Convert Unix timestamps to human-readable dates and vice versa.</p>
            <Link to="/tools/unix-time-converter" className="tool-link">Try Now</Link>
          </div>
          <div className="tool-card">
            <h3>JSON Formatter and Validator</h3>
            <p>Format and validate JSON quickly and easily.</p>
            <Link to="/tools/json-formatter-and-validator" className="tool-link">Try Now</Link>
          </div>
          <div className="tool-card">
            <h3>Text Compare</h3>
            <p>Compare text files and snippets to find differences.</p>
            <Link to="/tools/text-compare" className="tool-link">Try Now</Link>
          </div>
          <div className="tool-card">
            <h3>Base64 String Encoder/Decoder</h3>
            <p>Encode and decode Base64 strings with ease.</p>
            <Link to="/tools/base64-string-encode-decode" className="tool-link">Try Now</Link>
          </div>
          <div className="tool-card">
            <h3>Base64 Image Encoder/Decoder</h3>
            <p>Convert images to Base64 strings and back.</p>
            <Link to="/tools/base64-image-encode-decode" className="tool-link">Try Now</Link>
          </div>
        </div>
      </section>

      <section id="testimonials">
        <h2>What Our Users Say</h2>
        <div className="testimonials-list">
          <div className="testimonial">
            <p>"The Code Wrench has significantly improved my workflow. All the tools I need are in one place!"</p>
            <p>- Jane Doe, Software Engineer</p>
          </div>
          <div className="testimonial">
            <p>"A must-have for any developer. The JSON Formatter is my favorite!"</p>
            <p>- John Smith, Data Analyst</p>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Home;
