import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import styles from './Base64StringEncoderDecoder.module.css'; // Import CSS module
import { getUserInput, setUserInput } from '../../../utils/UserState';

const Base64StringEncoderDecoder = ({ userId }) => {
  const toolName = 'Base64StringEncoderDecoder';
  const initialInputValue = getUserInput(userId, toolName);

  const [inputText, setInputText] = useState(initialInputValue || '');
  const [outputText, setOutputText] = useState('');
  const [operation, setOperation] = useState('encode'); // 'encode' or 'decode'
  const [error, setError] = useState('');
  const inputRef = useRef(null);

  const encodeBase64 = useCallback((text) => {
    try {
      const encoded = btoa(text);
      setOutputText(encoded);
      setError('');
    } catch (err) {
      setOutputText('');
      setError(`Error encoding: ${err.message}`);
    }
  }, []);

  const decodeBase64 = useCallback((text) => {
    try {
      const decoded = atob(text);
      setOutputText(decoded);
      setError('');
    } catch (err) {
      setOutputText('');
      setError(`Error decoding: ${err.message}`);
    }
  }, []);

  const performOperation = useCallback((text) => {
    if (operation === 'encode') {
      encodeBase64(text);
    } else if (operation === 'decode') {
      decodeBase64(text);
    }
  }, [operation, encodeBase64, decodeBase64]);

  useEffect(() => {
    if (inputText) {
      performOperation(inputText);
    }
  }, [operation, inputText, performOperation]);

  const handleInputChange = (event) => {
    const text = event.target.value;
    setInputText(text);
    setUserInput(userId, toolName, text);
    performOperation(text);
  };

  const handleClear = () => {
    setInputText('');
    setOutputText('');
    setError('');
    inputRef.current.focus();
    setUserInput(userId, toolName, '');
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(outputText);
  };

  const handleUseAsInput = () => {
    const newOperation = operation === 'encode' ? 'decode' : 'encode';
    setInputText(outputText);
    setOperation(newOperation);
  };

  const handleSample = () => {
    const sampleText = operation === 'encode' ? 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' : 'TG9yZW0gaXBzdW0gZG9sb3Igc2l0IGFtZXQsIGNvbnNlY3RldHVyIGFkaXBpc2NpbmcgZWxpdC4=';
    setInputText(sampleText);
    setUserInput(userId, toolName, sampleText);
    performOperation(sampleText);
  };

  const handleRadioChange = (event) => {
    const selectedOperation = event.target.value;
    setOperation(selectedOperation);
  };

  return (
    <div className={styles['tool-content']}>
      <div className={styles['input-section']}>
        <div className={styles['input-actions']}>
          <button className={styles['action-button']} onClick={handleClear}>Clear</button>
          <button className={styles['action-button']} onClick={handleSample}>Sample</button>
          <div className={styles['radio-buttons']}>
            <label>
              <input
                type="radio"
                value="encode"
                checked={operation === 'encode'}
                onChange={handleRadioChange}
              />
              Encode
            </label>
            <label>
              <input
                type="radio"
                value="decode"
                checked={operation === 'decode'}
                onChange={handleRadioChange}
              />
              Decode
            </label>
          </div>
        </div>
        <textarea
          ref={inputRef}
          placeholder="Enter text..."
          value={inputText}
          onChange={handleInputChange}
          className={`${styles['text-area']} ${error ? styles['error'] : ''}`}
        ></textarea>
      </div>
      <div className={styles['output-section']}>
        <textarea
          readOnly
          value={outputText}
          className={`${styles['text-area']} ${error ? styles['error'] : ''}`}
        ></textarea>
        <div className={styles['input-actions']}>
          <button className={styles['action-button']} onClick={handleCopy}>
            <FontAwesomeIcon icon={faCopy} /> Copy
          </button>
          <button className={styles['action-button']} onClick={handleUseAsInput}>
            Use as Input
          </button>
        </div>
      </div>
      {error && <div className={styles['tool-error']}>{error}</div>}
    </div>
  );
};

export default Base64StringEncoderDecoder;
