import UnixTimeConverter from '../components/Tools/UnixTimeConverter/UnixTimeConverter';
import JsonFormatterValidator from '../components/Tools/JsonFormatterValidator/JsonFormatterValidator';
import Base64StringEncoderDecoder from '../components/Tools/Base64StringEncoderDecoder/Base64StringEncoderDecoder';
import Base64ImageEncoderDecoder from '../components/Tools/Base64ImageEncoderDecoder/Base64ImageEncoderDecoder';
import TextCompare from '../components/Tools/TextCompare/TextCompare';
// import { 
//   UnixTimeConverter, JsonFormatterValidator, Base64StringEncoderDecoder, Base64ImageEncoderDecoder,
//   JwtDebugger, RegExpTester, UrlEncodeDecode, UrlParser, HtmlEntityEncodeDecode, BackslashEscapeUnescape,
//   UuidUlidGenerateDecode, HtmlPreview, TextDiffChecker, YamlToJson, JsonToYaml, NumberBaseConverter,
//   HtmlBeautifyMinify, CssBeautifyMinify, JsBeautifyMinify, ErbBeautifyMinify, LessBeautifyMinify, ScssBeautifyMinify,
//   XmlBeautifyMinify, LoremIpsumGenerator, QrCodeReaderGenerator, StringInspector, JsonToCsv, CsvToJson,
//   HashGenerator, HtmlToJsx, MarkdownPreview, SqlFormatter, StringCaseConverter, CronJobParser, ColorConverter,
//   PhpToJson, JsonToPhp, PhpSerializer, PhpUnserializer, RandomStringGenerator, SvgToCss, CurlToCode, JsonToCode,
//   CertificateDecoder, HexToAscii, AsciiToHex, LineSortDedupe 
// } from '../tools';
import { 
  faClock, faCode, faStrikethrough,faImage, faFileCode
  // , faUserSecret, faAsterisk, 
  // faLink, faLinkSlash, faFileCode, faTextHeight, faFingerprint, faEye,
  // faPuzzlePiece, faCheckSquare, faCompress, faExpand, faBook,
  // faCheck, faTasks, faProjectDiagram, faRandom, faQrcode, 
  // faSearch, faExchangeAlt, faHashtag, faColumns, faTerminal, faDrawPolygon,
  // faCogs, faKey, faCertificate, faAlignLeft, faSort
} from '@fortawesome/free-solid-svg-icons';

const Tools = [
   { name: "Unix Time Converter", icon: faClock, component: UnixTimeConverter, slug: "unix-time-converter" }, 
   { name: "JSON Formatter and Validator", icon: faCode, component: JsonFormatterValidator, slug: "json-formatter-and-validator" }, 
   { name: "Text Compare", icon: faFileCode, component: TextCompare, slug: "text-compare" }, 
   { name: "Base64 String Encode/Decode", icon: faStrikethrough, component: Base64StringEncoderDecoder, slug: "base64-string-encode-decode" }, 
   { name: "Base64 Image Encode/Decode", icon: faImage, component: Base64ImageEncoderDecoder, slug: "base64-image-encode-decode" }, 
  //  { name: "JWT Debugger", icon: faUserSecret, component: UnixTimeConverter, slug: "jwt-debugger" }, 
  //  { name: "RegExp Tester", icon: faAsterisk, component: UnixTimeConverter, slug: "regexp-tester" }, 
  //  { name: "URL Encode/Decode", icon: faLink, component: UnixTimeConverter, slug: "url-encode-decode" }, 
  //  { name: "URL Parser", icon: faLinkSlash, component: UnixTimeConverter, slug: "url-parser" }, 
  //  { name: "HTML Entity Encode/Decode", icon: faFileCode, component: UnixTimeConverter, slug: "html-entity-encode-decode" }, 
  //  { name: "Backslash Escape/Unescape", icon: faTextHeight, component: UnixTimeConverter, slug: "backslash-escape-unescape" }, 
  //  { name: "UUID/ULID Generate/Decode", icon: faFingerprint, component: UnixTimeConverter, slug: "uuid-ulid-generate-decode" }, 
  //  { name: "HTML Preview", icon: faEye, component: UnixTimeConverter, slug: "html-preview" }, 
  //  { name: "Text Diff Checker", icon: faCheckSquare, component: UnixTimeConverter, slug: "text-diff-checker" }, 
  //  { name: "YAML to JSON", icon: faCompress, component: JsonFormatterValidator, slug: "yaml-to-json" }, 
  //  { name: "JSON to YAML", icon: faExpand, component: JsonFormatterValidator, slug: "json-to-yaml" }, 
  //  { name: "Number Base Converter", icon: faBook, component: UnixTimeConverter, slug: "number-base-converter" }, 
  //  { name: "HTML Beautify/Minify", icon: faFingerprint, component: JsonFormatterValidator, slug: "html-beautify-minify" }, 
  //  { name: "CSS Beautify/Minify", icon: faCheck, component: JsonFormatterValidator, slug: "css-beautify-minify" }, 
  //  { name: "JS Beautify/Minify", icon: faTasks, component: JsonFormatterValidator, slug: "js-beautify-minify" }, 
  //  { name: "ERB Beautify/Minify", icon: faProjectDiagram, component: JsonFormatterValidator, slug: "erb-beautify-minify" }, 
  //  { name: "LESS Beautify/Minify", icon: faPuzzlePiece, component: JsonFormatterValidator, slug: "less-beautify-minify" }, 
  //  { name: "SCSS Beautify/Minify", icon: faRandom, component: JsonFormatterValidator, slug: "scss-beautify-minify" }, 
  //  { name: "XML Beautify/Minify", icon: faQrcode, component: JsonFormatterValidator, slug: "xml-beautify-minify" }, 
  //  { name: "Lorem Ipsum Generator", icon: faSearch, component: UnixTimeConverter, slug: "lorem-ipsum-generator" }, 
  //  { name: "QR Code Reader/Generator", icon: faExchangeAlt, component: UnixTimeConverter, slug: "qr-code-reader-generator" }, 
  //  { name: "String Inspector", icon: faHashtag, component: UnixTimeConverter, slug: "string-inspector" }, 
  //  { name: "JSON to CSV", icon: faColumns, component: JsonFormatterValidator, slug: "json-to-csv" }, 
  //  { name: "CSV to JSON", icon: faTerminal, component: JsonFormatterValidator, slug: "csv-to-json" }, 
  //  { name: "Hash Generator", icon: faDrawPolygon, component: UnixTimeConverter, slug: "hash-generator" }, 
  //  { name: "HTML to JSX", icon: faCogs, component: JsonFormatterValidator, slug: "html-to-jsx" }, 
  //  { name: "Markdown Preview", icon: faKey, component: UnixTimeConverter, slug: "markdown-preview" }, 
  //  { name: "SQL Formatter", icon: faCertificate, component: UnixTimeConverter, slug: "sql-formatter" }, 
  //  { name: "String Case Converter", icon: faAlignLeft, component: UnixTimeConverter, slug: "string-case-converter" }, 
  //  { name: "Cron Job Parser", icon: faTasks, component: UnixTimeConverter, slug: "cron-job-parser" }, 
  //  { name: "Color Converter", icon: faProjectDiagram, component: UnixTimeConverter, slug: "color-converter" }, 
  //  { name: "PHP to JSON", icon: faPuzzlePiece, component: JsonFormatterValidator, slug: "php-to-json" }, 
  //  { name: "JSON to PHP", icon: faRandom, component: JsonFormatterValidator, slug: "json-to-php" }, 
  //  { name: "PHP Serializer", icon: faQrcode, component: JsonFormatterValidator, slug: "php-serializer" }, 
  //  { name: "PHP Unserializer", icon: faSearch, component: JsonFormatterValidator, slug: "php-unserializer" }, 
  //  { name: "Random String Generator", icon: faExchangeAlt, component: UnixTimeConverter, slug: "random-string-generator" }, 
  //  { name: "SVG to CSS", icon: faHashtag, component: UnixTimeConverter, slug: "svg-to-css" }, 
  //  { name: "cURL to Code", icon: faColumns, component: UnixTimeConverter, slug: "curl-to-code" }, 
  //  { name: "JSON to Code", icon: faTerminal, component: JsonFormatterValidator, slug: "json-to-code" }, 
  //  { name: "Certificate Decoder (X.509)", icon: faDrawPolygon, component: UnixTimeConverter, slug: "certificate-decoder-x509" }, 
  //  { name: "Hex to ASCII", icon: faCogs, component: UnixTimeConverter, slug: "hex-to-ascii" }, 
  //  { name: "ASCII to Hex", icon: faKey, component: UnixTimeConverter, slug: "ascii-to-hex" }, 
  //  { name: "Line Sort/Dedupe", icon: faSort, component: UnixTimeConverter, slug: "line-sort-dedupe" } 
];


export default Tools;
