import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import ToolCard from './components/ToolCard/ToolCard';
import ToolPlayground from './components/ToolPlayground/ToolPlayground';
import { getUserSelectedTool, setUserSelectedTool } from './utils/UserState';
import ReactGA from 'react-ga';
import './App.css';
import config from './config/default';

ReactGA.initialize(config.reactGATrackId);

const App = () => {
  const { slug } = useParams();
  const userId = "user1";
  const initialSelectedTool = slug === config.ToolNotFound ? '' : slug || getUserSelectedTool(userId);
  const [selectedTool, setSelectedTool] = useState(initialSelectedTool);
  const [, setToolCardWidth] = useState('auto');
  const toolCardRef = useRef(null);

  useEffect(() => {
    setUserSelectedTool(userId, selectedTool);
  }, [selectedTool, userId]);

  const handleExpand = () => {
    if (toolCardRef.current) {
      const cardWidth = toolCardRef.current.scrollWidth;
      setToolCardWidth(`${cardWidth}px`);
    }
  };

  const handleMinimize = () => {
    if (!toolCardRef.current.querySelector('input').value) {
      setToolCardWidth('auto');
    }
  };

  return (
    <div className="app">
      <Header />
      <div className="mainContent">
        <div className="toolCard" ref={toolCardRef}>
          <ToolCard setSelectedTool={setSelectedTool} onExpand={handleExpand} onMinimize={handleMinimize} />
        </div>
        <div className="toolPlayground">
          <ToolPlayground selectedTool={selectedTool} userId={userId} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default App;
